.darkmode {
  background-color: #121212 !important;
  color: #888 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-marker {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
}

.infoBox {
  padding: 1em;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 100;
}


.note,
.note > div {
  display: none;
  border: 1px dashed darkgrey;
  background: yellow;
  font-size: 10px;
}


a {
  color: darkslategrey; /* Set your desired color here */
  text-decoration: none; /* Optional: to remove underline */
}

a:visited {
  color: darkslategrey; /* Same color for visited state */
}

a:hover {
  color: darkslategrey; /* Same color for hover state */
  text-decoration: none; /* Optional: to remove underline on hover */
}

a:active {
  color: darkslategrey; /* Same color for active state */
}


.treeItemParent {
  padding-bottom: 1rem;
  treeItemChild {

  }
}
