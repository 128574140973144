.newStorageUnitBtn {
  background-color: crimson !important;
  box-shadow: none !important;
  border-radius: 3px !important;

  &:hover {
    box-shadow: none !important;
    background-color: rgb(168, 17, 47) !important;
  }
}
#newProductInputField {
  color: gray;
  border: none;
  border-left: 1px solid rgba(128, 128, 128, 0.4);
  border-right: 0px solid white;
  height: 60px;
  width: 200px;
  padding: 10px;
  transition: 0.5s;
  background-color: white;
  border-top-right-radius: 5px;

  &:hover {
    border-bottom: 1px solid crimson !important;
    width: 400px;
  }

  &:focus {
    border-bottom: 1px solid crimson !important;
    outline: none;
    width: 400px;
  }
}

/* old table css */

.divTableContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  padding-left: 20px;
  border: 1px solid rgba(128, 128, 128, 0.212);
  border-left: none;
  border-right: none;
  border-bottom: none;
  transition: 0.5s;
  cursor: pointer;
  color: darkred;

  &:nth-child(odd) {
    background-color: rgba(128, 128, 128, 0.1);
  }

  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
  }

  .customer {
    display: flex;
    align-items: center;
    justify-content: start;

    width: 100%;
  }

  .deleteBtn {
    display: flex;
    align-items: center;
    justify-content: end;
    color: crimson;
    width: 50px;
  }

  .editBtn {
    display: flex;
    align-items: center;
    justify-content: end;
    color: gray;
    width: 50px;
    cursor: pointer;
  }
}

.divSubTableContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  padding-left: 20px;
  border: 1px solid rgba(128, 128, 128, 0.212);
  border-left: none;
  border-right: none;
  border-bottom: none;
  transition: 0.5s;
  cursor: pointer;
  color: darkslategrey;

  &:hover {
    background-color: #f2eaea;
  }

  &.table-header {
    border: 0;
    font-weight: bold;
  }

  .customer {
    display: flex;
    align-items: center;
    justify-content: start;

    width: 100%;
  }

  .deleteBtn {
    display: flex;
    align-items: center;
    justify-content: end;
    color: crimson;
    width: 50px;
  }

  .editBtn {
    display: flex;
    align-items: center;
    justify-content: end;
    color: gray;
    width: 50px;
    cursor: pointer;
  }
}
/* old table css */

.tableContentTree {
  width: 100%;
  padding: 5px;
  padding-left: 20px;
  border: 1px solid rgba(128, 128, 128, 0.212);
  border-left: none;
  border-right: none;
  border-bottom: none;
  transition: 0.5s;
  cursor: pointer;
  color: gray;

  &:nth-child(odd) {
    background-color: rgba(128, 128, 128, 0.1);
  }

  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
  }

  .customer {
    display: flex;
    align-items: center;
    justify-content: start;

    width: 100%;

    &.actions {
      display: flex;
      width: 200px;
    }
  }

  .deleteBtn {
    display: flex;
    align-items: center;
    justify-content: end;
    color: crimson;
    width: 50px;
  }

  .editBtn {
    display: flex;
    align-items: center;
    justify-content: end;
    color: gray;
    width: 50px;
    cursor: pointer;
  }
}

.tenants {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.tenant-toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px;

  .gap {
    flex: 1;
  }
}

.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: white;
  padding: 20px;
  box-shadow: 24px;
  outline: none;
}

.tenantTable {
  margin-top: 10px;
  width: 100%;
  > table {
    width: 100%;
  }
}

// ########### TODO general style
// also in customer
.contentTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
  //   rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  // border: 1px solid black;
  border-radius: 5px;

  .link {
    color: gray;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;

    height: 100%;

    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    // box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    //   rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    //   rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
    // box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    //   rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    //   rgba(0, 0, 0, 0.07) 0px 16px 16px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 1rem 1rem 1rem 1rem;
    margin-bottom: 2rem;
    // background-color: #5f3d3d;
    background-color: #f9f3f3;

    .customerTextInputBox {
      display: flex;
      align-items: center;
      right: 0;
      padding: 0;
      padding-left: 20px;
      height: 100%;

      #newCustomerInputField {
        color: gray;
        border: none;
        border-left: 1px solid rgba(128, 128, 128, 0.4);
        border-right: 0px solid white;
        height: 60px;
        width: 200px;
        padding: 10px;
        transition: 0.5s;
        background-color: white;
        border-top-right-radius: 5px;

        &:hover {
          border-bottom: 1px solid crimson !important;
          width: 400px;
        }

        &:focus {
          border-bottom: 1px solid crimson !important;
          outline: none;
          width: 400px;
        }
      }
      .addBoxDiv {
        position: absolute;
        right: 0;
        display: flex;
        padding: 0;
        margin-right: 10px;
        #addBox {
          width: 100%;
          height: 30px;
          color: crimson;
          margin-right: 30px;
          cursor: pointer;

          &:hover {
            color: gray;
          }
        }
      }
    }

    .cell1 {
      display: flex;
      align-items: center;
      left: 0;
      font-size: 18px;
      color: crimson;

      #categoryIcon {
        font-size: 28px;
        padding-right: 0.5rem;
        color: crimson;
      }
    }
  }
}

.propertyDetails {
  margin-bottom: 10px;
  //   // margin-left: -1rem;
  //   // margin-right: -1rem;
  //   box-shadow: none;
  //   /* border-bottom: 2px solid; */
  //   box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
  //     rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
  //     rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
  //   margin-bottom: 2rem;
}
