.loginMain {
  display: flex;
  background-color: transparent;
  width: 100vw;
  height: 100vh;

  .loginLeft {
    background-color: transparent;
    flex: 4;

    .bg {
      animation: slide 3s ease-in-out infinite alternate;
      background-image: linear-gradient(
        60deg,
        crimson 50%,
        rgb(56, 56, 56) 50%
      );
      bottom: 0;
      left: -50%;
      opacity: 0.8;
      position: fixed;
      right: -50%;
      top: 0;
      z-index: -1;
    }

    .bg2 {
      animation-direction: alternate-reverse;
      animation-duration: 7s;
    }

    .bg3 {
      animation-duration: 9s;
    }
  }

  .loginRight {
    background-color: white;
    flex: 6;
  }
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

.loginFormContainer {
  position: absolute;
  left: 50%;
  margin-left: -25%;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  top: 50%;
  margin-top: -25rem;
  border-radius: 10px;

  /* justify-content: center; */
  height: 50rem;
  width: 50%;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  .loginFormLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2;
    background-color: transparent !important;
    height: 100%;

    .StoreMonText {
      color: white;
      text-align: center;
      font-size: 50px;
      font-weight: bold;
      margin-top: 20px;
      line-height: 70px;
      transform: rotate(-90deg);
      cursor: default;
    }
  }

  .loginFormRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 4;
    background-color: white;
    height: 100%;
    border-radius: 10px;

    .loginLogo {
      max-width: 100vw;
      width: 250px;
      padding-bottom: 10vh;
    }

    .loginForm {
      display: flex;
      flex-flow: column;
      padding: 1rem;
      width: 25vw;
      background-color: transparent !important;
      box-shadow: none !important;

      .loginHeaderText {
        color: black;
        font-size: 25px;
        text-align: center;
        font-weight: bold;
        margin-top: 10px;
        cursor: default;
      }

      .loginHeaderUnderline {
        color: rgb(180, 180, 180);
        font-size: 14px;
        text-align: center;
        padding-bottom: 30px;
        cursor: default;
      }

      .loginErrorText {
        display: flex;
        align-items: center;
        justify-content: center;
        color: crimson;
        text-align: center;
        cursor: default;
        font-weight: bold;
      }

      .errorIcon {
        margin-right: 10px;
      }

      .isCapsText {
        display: flex;
        align-items: center;
        justify-content: center;
        color: crimson;
        text-align: center;
        cursor: default;
        font-weight: bold;
        margin-top: 20px;
      }

      .loginFormText {
        color: black !important;
      }

      input {
        color: black !important;
        border-top: 1px solid transparent !important;
        border-bottom: 1px solid white !important;
      }

      label {
        color: black !important;
        border: none !important;
      }

      .loginButton {
        margin-top: 1rem !important;
        background-color: crimson !important;
        width: 100%;
      }
    }
  }
}
