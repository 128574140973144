.allStorageSegmentInputsBox {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .sensorSelectBox {
    margin-bottom: 1rem;
  }
  .storageUnitNameBox {
    margin-bottom: 1rem;

    .storageSegmentTextInput {
      width: 100%;
    }
  }
  .numberTextInputBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}

.sensorFormControl {
  width: 300px;
}
