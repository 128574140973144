a {
  text-decoration: none;

  &:hover li {
    background-color: #dc143c33 !important;
  }

  &:hover span {
    color: black !important;
  }

  &:active li {
    background-color: #dc143c33 !important;
  }

  &:active .icon {
    color: hsl(348, 84%, 29%) !important;
  }

  &:focus li {
    background-color: #dc143c33 !important;

    &:hover {
      background-color: #dc143c21 !important;
    }
  }

  &:focus .icon {
    color: hsl(348, 84%, 29%) !important;
  }

  &:focus span {
    color: black !important;
    font-weight: normal !important;
  }

  &:visited li {
    background-color: #dc143c33 !important;
  }
}

.iconButton {
  color: crimson !important;

  &:hover {
    background-color: transparent !important;
    color: hsl(348, 84%, 29%) !important;
  }
}

.Navbar {
  position: fixed;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 14px;

  .Toolbar {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    color: #555;
    // border-bottom: 1px solid lightgray !important;

    .IconMenu {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .Headline {
      display: none;
      align-items: center;
      font-size: 20px;
      font-weight: normal;
      text-transform: uppercase;
    }

    .items {
      display: flex;
      align-items: center;

      .Mail {
        padding: 10px;
      }

      .icons {
        font-size: 20px;
        cursor: pointer;
      }

      .logout {
        color: hsl(348, 83%, 47%);
        background-color: transparent;

        &:hover {
          color: hsl(348, 84%, 29%);
          background-color: transparent;
        }

        .text {
          text-transform: lowercase;
          margin-left: 5px;
        }
      }
    }
  }
}

.drawerHeader {
  // border-right: 1px solid lightgray;
  // border-bottom: 1px solid lightgray;
  border: 0;
}

.drawer {
  overflow: hidden;

  .Logo {
    display: flex;
    align-items: center;
  }

  .sidebar {
    flex: 1;
    // border-right: 1px solid lightgray;
    min-height: 100vh;
    background-color: white;
    overflow: auto;

    padding-left: 0.5rem;
    p.title {
      margin-top: 1.5rem;
    }

    .top {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    hr {
      height: 0;
      border: 0.1px solid rgb(240, 240, 240, 1);
    }

    .center {
      padding-left: 10px;
      overflow: hidden;

      .ulWrapper {
        .liWrapper {
          margin-top: 0px;
          padding: 12px;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        .title {
          font-size: 12px;
          font-weight: bold;
          color: #999;
          margin-top: 15px;
          margin-bottom: 5px;
        }

        li {
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: background-color 0.1s ease-out 50ms !important;

          &:hover .icon {
            color: hsl(348, 84%, 29%) !important;
          }

          .icon {
            font-size: 20px;
            color: #dc143c;
          }

          span {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: normal;
            color: #888;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
