.dialogue {
  .dialogueDiv {
    display: flex;
    width: 100%;
    flex-direction: column;
    .dialogueTitleBox {
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding-top: 10px;
      height: max-content;
      color: crimson;

      font-weight: bold;
      text-transform: uppercase;
      display: flex;

      .dialogueDelIcon {
        display: block;
        color: crimson;

        font-size: 30px;
        bottom: -10px;
      }
      .noDialogueDelIcon {
        display: none;
      }
    }
    .contentActionDiv {
      background-color: hsl(0, 0%, 97%);
      border-top: 1px solid rgba(128, 128, 128, 0.1);
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .dialogueContent {
        height: max-content;
        text-align: left;
        width: 100%;
        overflow-y: clip !important;
      }
      .dialogueActions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 40px;
        padding-right: 20px;
        .cancelBtn {
          background-color: white !important;
          font-weight: bold;
          color: gray;
          margin-right: 10px;
          width: 150px;
        }

        .successBtn {
          background-color: crimson !important;
          font-weight: bold;
          width: 150px;
        }
      }
    }
  }
}
